import React from "react"
import Spinner from "../Spinner"
import { info } from "console"

export type InfoMsgType = {
  isLoading: boolean
  status?: "ok" | "error"
  msg?: string
}

type PropTypes = {
  infoMsg: InfoMsgType
  containerClassName?: string
  textClassName?: string
  withSpinner?: boolean
}

export default function InfoMsg({
  infoMsg,
  containerClassName = "",
  textClassName = "",
  withSpinner = false,
}: PropTypes) {
  if (infoMsg.msg != null || (withSpinner && infoMsg.isLoading))
    return (
      <div className={"infoMsgContainer mT8 " + containerClassName}>
        {withSpinner && (
          <Spinner
            containerClassName="appInfoLoadingContainer"
            //   containerStyle={{
            //     width: 60,
            //     height: 60,
            //     margin: "0 auto",
            //   }}
            show={infoMsg.isLoading}
          />
        )}
        {infoMsg.msg != null && (
          <span
            className={
              "infoMsgText text14 " +
              (infoMsg.status != null
                ? infoMsg.status === "error"
                  ? "colorRed "
                  : "colorGreen "
                : "colorPrimary ") +
              textClassName
            }
          >
            {infoMsg.msg}
          </span>
        )}
      </div>
    )
  else return null
}
